/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useRef } from 'react';
import {
    object, string, func, shape, arrayOf, number,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Product from './Product';

const styles = (theme) => ({
    phraseSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '0px',
        '& li': {
            padding: '5px 0px',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.cms?.autoSuggestPhrase || '#3c3c35',
                fontSize: '15px',
                padding: '5',
                fontWeight: 'bold',
            },
            '& span': {
                fontWeight: 'normal',
            },
        },
    },
    autoSearchResultContainer: {
        position: 'absolute',
        zIndex: '25',
        background: theme.palette.cms?.autoSuggestBackground || '#fff',
        top: '45px',
        left: 30,
        width: '100%',
        border: `1px solid ${theme.palette.cms?.autoSuggestBorder || '#d8d8d8'}`,
        borderTop: 'none',
        borderBottomRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
        maxHeight: 'calc(100vh - 102px)',
        overflowY: 'auto',
    },
    productListSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '3px 0',
        '& li': {
            display: 'inline-block',
            minHeight: '80px',
            maxHeight: '80px',
            width: '100%',
            marginBottom: '0px',
            '& a': {
                padding: '4px 0',
                marginBottom: '10px',
                color: theme.palette.cms?.autoSuggestProductList || '#3c3c35',
                textDecoration: 'none',
                wordWrap: 'break-word',
                display: 'block',
                fontSize: '14px',
                '& img': {
                    float: 'left',
                    width: 'calc(52px + 12px)',
                    paddingRight: '12px',
                    [theme.breakpoints.down(700)]: {
                        padding: '0 12px 40px 0',
                    },
                    display: 'block',
                },
                '& span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.down(700)]: {
                        whiteSpace: 'normal',
                    },
                    display: 'block',
                },
            },
            '& span': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                [theme.breakpoints.down(700)]: {
                    whiteSpace: 'normal',
                },
                display: 'block',
            },
        },
    },
    thumbName: {
        padding: '5px 0',
    },
    thumbPrice: {
        padding: '5px 0 10px',
        fontWeight: 600,
    },
    phraseBorderRight: {
        borderRight: `${'1px solid'} ${theme.palette.cms?.autoSuggestBorderColor || '#d8d8d8'}`,
        [theme.breakpoints.down('xs')]: {
            borderRight: 0,
        },
    },
    menuItem: {
        [theme.breakpoints.down(600)]: {
            width: '90%',
        },
    },
});
const getPrice = (priceList, type = 'sale') => {
    let price = null;
    const priceObject = priceList.find((itemPrice) => itemPrice.type.toLowerCase() === type);
    if (priceObject) {
        price = priceObject.value;
    }
    return price;
};

const suggestionListItems = (phrases, value, clearSearch, track, concatSearchTerm, cleanTerm, classes) => {
    const suggestionList = [];
    let response = null;
    if (phrases?.length) {
        const inputVal = value.toLowerCase();
        phrases.forEach((phrase) => {
            const data = {};
            data.name = phrase.toLowerCase().replace(inputVal, `<span>${inputVal}</span>`);
            data.inputString = phrase.toLowerCase();
            data.link = concatSearchTerm(cleanTerm(phrase.toLowerCase())?.replace(/\s+/g, '+'), phrase); // replacing spaces with +
            suggestionList.push(data);
        });
    }
    if (suggestionList.length) {
        response =  suggestionList.map((data) => (
            <li
                key={data.link}
                role="menuitem"
                className={classes.menuItem}
            >
                <Link
                    tabIndex="0"
                    to={`/searchterm/${data.link}`}
                    onClick={() => {
                        clearSearch(true, data.inputString);
                    }}

                >
                    {ReactHtmlParser(data.name)}
                </Link>
            </li>
        ));
    }
    return response;
};

const SearchDropDown = ({
    phrase, suggestions, classes, clearSearch, track, concatSearchTerm, cleanTerm,
}) => {
    const htmlRef =  useRef([]);

    useEffect(() => {
        const listItems = htmlRef?.current?.[0]?.children;
        const listProducts =  htmlRef?.current?.[1]?.children;
        const productListLength = suggestions?.products?.length || 0;
        const listItemsLength = suggestions?.phrases?.length || 0;
        let i = -1;
        let index = -1;
        const listFunc = (event) => {
            if (event.keyCode === 38 && (i > 0 || index > 0)) {
                if (index > 0) {
                    i = listItemsLength;
                    index -= 1;
                    listProducts?.[index]?.children?.[0].focus();
                } else {
                    i -= 1;
                    listItems?.[i]?.children?.[0].focus();
                }
                event.preventDefault();
                event.stopPropagation();
            } else if (event.keyCode === 40) {
                if (i < listItemsLength  && i !== listItemsLength - 1) {
                    i += 1;
                    index = -1;
                    listItems?.[i]?.children?.[0].focus();
                } else {
                    if (productListLength - 1 === index) {
                        i = -1;
                        index -= 1;
                    }
                    index += 1;
                    listProducts?.[index]?.children?.[0].focus();
                }
                event.preventDefault();
                event.stopPropagation();
            }
        };
        if (typeof document !== 'undefined' && (listItems || listProducts)) {
            document.addEventListener('keydown', listFunc, false);
        }
        return () => {
            document.removeEventListener('keydown', listFunc);
        };
    }, []);

    return (
        <>
            {(suggestions?.products?.length > 0 || suggestions?.phrases?.length > 0) && (
                <div className={classes.autoSearchResultContainer}>
                    <Grid container>
                        {suggestions?.phrases?.length > 0 && (
                            <Grid item sm={suggestions?.products?.length > 0 ? 4 : 12} xs={12} className={suggestions?.products?.length > 0 ? classes.phraseBorderRight : ''}>
                                <ul
                                    ref={(element) => {
                                        htmlRef.current[0] = element;
                                    }}
                                    className={classes.phraseSuggestions}
                                    id="suggestion"
                                >
                                    {suggestionListItems(suggestions.phrases, phrase, clearSearch, track, concatSearchTerm, cleanTerm, htmlRef, classes)}
                                </ul>
                            </Grid>
                        )}
                        {(suggestions?.products?.length > 0) && (
                            <Grid item sm={8} xs={12}>
                                <ul
                                    ref={(element) => {
                                        htmlRef.current[1] = element;
                                    }}
                                    // ref={htmlRef}
                                    className={classes.productListSuggestions}
                                >
                                    {suggestions?.products?.map((data) => (
                                        <li
                                            key={data?.seo?.url}
                                            role="menuitem"
                                        >
                                            <Product
                                                data={data}
                                                phrase={phrase}
                                                track={track}
                                                concatSearchTerm={concatSearchTerm}
                                                clearSearch={clearSearch}
                                                getPrice={getPrice}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        )}

                    </Grid>
                </div>
            )}
        </>
    );
};
SearchDropDown.propTypes = {
    classes: object.isRequired,
    suggestions: shape({
        codes: arrayOf(string),
        phrases: arrayOf(string),
        products: arrayOf(shape({
            name: string,
            image: shape({
                name: string,
                path: string,
            }),
            seo: shape({
                url: string,
            }),
            prices: arrayOf(
                shape({
                    type: string,
                    value: number,
                }),
            ),
        })),
    }).isRequired,
    phrase: string.isRequired,
    clearSearch: func.isRequired,
    track: func.isRequired,
    concatSearchTerm: func.isRequired,
    cleanTerm: func.isRequired,
};

export default withStyles(styles)(SearchDropDown);
